import React, { useState, useEffect } from 'react';
import './ExploreContributorProfiles.css';
import GoogleAnalytics from '../../../Components/Helpers/GoogleAnalytics';
import Fade from 'react-reveal/Fade';
import { IconChevronDown } from '@tabler/icons-react';
import { Popover, Text } from '@mantine/core';
import ImageLoader from '../../../Components/Helpers/ImageLoader';

const ExploreContributorProfiles = React.forwardRef((props, ref) => {
    const { windowWidth } = props;
    const [limit, setLimit] = useState(4);
    const [displayLimit, setDisplayLimit] = useState(4);
    const [contributors, setContributors] = useState(null);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);

    useEffect(() => {
        let signedUser = JSON.parse(localStorage.getItem('aa-signed-user') || '{}') || {};
        setIsUserSignedIn(!!signedUser?.username)
    }, []);

    let list = [
        {
            name: 'Jana & Matej',
            url: '/creators/dreamyadventurers',
            instagram: 'dreamyadventurers',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/dreamyadventurers_2.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_dreamyadventurers_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_dreamyadventurers_5_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_dreamyadventurers_6_2.jpg'
            ],
            location: 'Banff, Canada',
            stats: {
                places: 44,
                countries: 5,
                cities: null,
                reactions: 109
            }
        },
        {
            name: 'Sarah',
            url: '/creators/nanaimonoms',
            instagram: 'nanaimonoms',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/nanaimonoms_2.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_nanaimonoms_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_nanaimonoms_5_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_nanaimonoms_6_2.jpg'
            ],
            location: 'Nanaimo, Canada',
            stats: {
                places: 14,
                countries: null,
                cities: 7,
                reactions: 33
            }
        },
        {
            name: 'Ryan & Katy',
            url: '/creators/smilkos_lens',
            instagram: 'smilkos_lens',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/smilkos_lens_3.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_smilkos_lens_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_smilkos_lens_5_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_smilkos_lens_6_2.jpg'
            ],
            location: 'Santa Rosa, USA',
            stats: {
                places: 15,
                countries: null,
                cities: 11,
                reactions: 34
            }
        },
        {
            name: 'Elnar',
            url: '/creators/eelnar',
            instagram: 'eelnar',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/pp-eelnar-187901.webp',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_eelnar_6.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_eelnar_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_eelnar_3.jpg'
            ],
            location: 'San Francisco, USA',
            stats: {
                places: 12,
                countries: null,
                cities: 10,
                reactions: 22
            }
        },
        {
            name: 'Jacalyn',
            url: '/creators/jacalyn.jess',
            instagram: 'jacalyn.jess',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/jacalyn.jess_3.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_jacalyn.jess_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_jacalyn.jess_2_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_jacalyn.jess_3_2.jpg'
            ],
            location: 'Toronto, Canada',
            stats: {
                places: 4,
                countries: null,
                cities: 1,
                reactions: 10
            }
        },
        {
            name: 'Paige',
            url: '/creators/liveanddieonvi',
            instagram: 'liveanddieonvi',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/liveanddieonvi_4.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_liveanddieonvi_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_liveanddieonvi_4_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_liveanddieonvi_6_2.jpg'
            ],
            location: 'Vancouver, Canada',
            stats: {
                places: 16,
                countries: null,
                cities: 15,
                reactions: 31
            }
        },
        {
            name: 'Petra & Jan',
            url: '/creators/wanderingintwo',
            instagram: 'wanderingintwo',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/wanderingintwo.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wanderingintwo_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wanderingintwo_3_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wanderingintwo_6_2.jpg'
            ],
            location: 'Vancouver, Canada',
            stats: {
                places: 12,
                countries: 2,
                cities: null,
                reactions: 27
            }
        },
        {
            name: 'Shannon',
            url: '/creators/shannon.adventures',
            instagram: 'shannon.adventures',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/shannon.adventures_3.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_shannon.adventures_2_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_shannon.adventures_4_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_shannon.adventures_5_2.jpg'
            ],
            location: 'Calgary, Canada',
            stats: {
                places: 10,
                countries: null,
                cities: 4,
                reactions: 23
            }
        },
        {
            name: 'Emma & Jone',
            url: 'creators/foodieexplorers',
            instagram: 'foodieexplorers',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/foodieexplorers_2.jpeg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_foodieexplorers_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_foodieexplorers_4_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_foodieexplorers_5_2.jpg'
            ],
            location: 'Toronto, Canada',
            stats: {
                places: 10,
                countries: null,
                cities: 2,
                reactions: 27
            }
        },
        {
            name: 'Alyssa',
            url: '/creators/hike.lyss',
            instagram: 'hike.lyss',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/hike.lyss_4.webp',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_hike.lyss_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_hike.lyss_3_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_hike.lyss_6_2.jpg'
            ],
            location: 'Victoria, Canada',
            stats: {
                places: 13,
                countries: null,
                cities: 8,
                reactions: 26
            }
        },
        {
            name: 'Lenka',
            url: '/creators/lenda_skorepova',
            instagram: 'lenda_skorepova',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/lenda_skorepova_2.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_lenda_skorepova_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_lenda_skorepova_3_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_lenda_skorepova_5_2.jpg'
            ],
            location: 'Squamish, Canada',
            stats: {
                places: 5,
                countries: null,
                cities: 2,
                reactions: 8
            }
        },
        {
            name: 'Kyle & Alannah',
            url: '/creators/wayoftheroads',
            instagram: 'wayoftheroads',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/wayoftheroads_2.webp',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wayoftheroads_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wayoftheroads_5_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wayoftheroads_6_2.jpg'
            ],
            location: 'Tofino, Canada',
            stats: {
                places: 2,
                countries: null,
                cities: 2,
                reactions: 5
            }
        },
        {
            name: 'Marie',
            url: '/creators/pinesanddines',
            instagram: 'pinesanddines',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/pinesanddines.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_pinesanddines_2_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_pinesanddines_3_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_pinesanddines_4_2.jpg'
            ],
            location: 'Hamilton, Canada',
            stats: {
                places: 2,
                countries: 2,
                cities: null,
                reactions: 5
            }
        },
        {
            name: 'Sasha',
            url: '/creators/wandering.barefoot',
            instagram: 'wandering.barefoot',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/wandering.barefoot_3.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wandering.barefoot_1_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wandering.barefoot_3_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_wandering.barefoot_5_2.jpg'
            ],
            location: 'Vancouver, Canada',
            stats: {
                places: 5,
                countries: null,
                cities: 4,
                reactions: 11
            }
        },
        {
            name: 'Lauren & Kevin',
            url: '/creators/foxandpines',
            instagram: 'foxandpines',
            img: 'https://s3.us-east-2.amazonaws.com/arvel.photos/creators/foxandpines.jpg',
            places: [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_foxandpines_2_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_foxandpines_3_2.jpg',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/creators-section/web_cp_foxandpines_4_2.jpg'
            ],
            location: 'Seattle, USA',
            stats: {
                places: 8,
                countries: null,
                cities: 7,
                reactions: 21
            }
        }
    ]

    useEffect(function () {
        setContributors(list);
    }, []);

    useEffect(() => {
        if (windowWidth < 850) setLimit(200);
        else {
            setDisplayLimit(3);
            setLimit(3);
        }
    }, [windowWidth]);

    const handleViewProfile = (URL) => {
        GoogleAnalytics('', {
            category: "Main_page",
            action: 'Main_explore_profile'
        });

        if (windowWidth > 601) window.open(URL);
        else window.location.href = URL;
    };

    const handleShowMore = () => {
        setLimit(limit > displayLimit ? displayLimit : 200);
        if (limit > displayLimit) document.getElementById("explore-profiles-container").scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const handleJoinCommunity = (action) => {
        GoogleAnalytics('', {
            category: "Main_page",
            action: action
        });

        props.handleJoinCommunity();
    }

    const _renderHeader = () => {
        return <Popover width={250} position="top" withArrow shadow="md">
            <Popover.Target>
                <h2 className='ep-header'>Explore community <div>beta</div></h2>
            </Popover.Target>
            <Popover.Dropdown>
                <Text size="sm">Access is currently limited to shortlisted users, but hop on the waitlist and you'll be one of the first to experience our community when it launches soon!</Text>
            </Popover.Dropdown>
        </Popover>
    };

    const _renderStats = (stats) => {
        return <>
            <div className='display-flex stat'>
                <div className='value'>{stats?.countries ? stats?.countries : stats?.cities}</div>
                <p className='label'>{stats?.countries ? 'countries' : 'cities'}</p>
            </div>
            <div style={{ margin: '0 30px' }} className='display-flex stat'>
                <div className='value'>{stats?.places}</div>
                <p className='label'>memories</p>
            </div>
            <div className='display-flex stat'>
                <div className='value'>{stats?.reactions}</div>
                <p className='label'>reactions</p>
            </div>
        </>
    };

    return (
        <div ref={ref} id='explore-profiles-container' className='explore-profiles-container'>
            <Fade>
                <div className="explore-profiles-div">
                    {_renderHeader()}
                    <div className='ep-list'>
                        {
                            contributors?.slice(0, limit).map(function (node, index) {
                                return <div key={index + '_cont_profile'} className={`ep-profile-container ${'epp-container-' + index}`}>
                                    <div className='img-container' onClick={() => handleViewProfile(node?.url)}>
                                        <ImageLoader src={node.img} skeletonR={'100%'} />
                                    </div>
                                    <p className='epc-name' onClick={() => handleViewProfile(node?.url)}>{node.name}</p>
                                    <p className='epc-type' onClick={() => handleViewProfile(node?.url)}>{node.location}</p>
                                    <div className='display-flex stats-container' onClick={() => handleViewProfile(node?.url)}>
                                        {_renderStats(node?.stats)}
                                    </div>
                                    <div className='epc-img-div'>
                                        {node.places.map(function (img, index) {
                                            return <div onClick={() => handleViewProfile(node?.url)} className='epc-img-div-2' >
                                                <ImageLoader src={img} skeletonR={1} />
                                            </div>
                                        })}

                                    </div>
                                    <button className='ep-action-btn ep-view-prof' onClick={() => handleViewProfile(node?.url)}>view profile</button>
                                </div>;
                            })
                        }
                        {!isUserSignedIn && <button className='display-flex mob-exploremore-btn' onClick={() => handleJoinCommunity('Main_join_community_mob')}>Join community</button>}
                    </div>
                    {!isUserSignedIn && <button className='web-exploremore-btn' style={{ display: (limit > displayLimit ? 'flex' : 'none') }} onClick={() => handleJoinCommunity('Main_join_community_web')}>Join community</button>}
                    <button className='ep-more-btn' onClick={handleShowMore} style={{ display: (limit > displayLimit ? 'none' : 'flex') }}>
                        <span className='show-more-button-txt'>{limit > displayLimit ? 'show less' : 'show more'}</span>
                        <IconChevronDown width='18' height='18' style={{ transform: (limit > displayLimit ? 'rotate(180deg)' : 'rotate(0deg)'), transition: 'all 0.25s ease-in-out' }} color='rgb(63, 163, 240)' />
                    </button>
                </div>
            </Fade>
        </div>
    );
});

export default ExploreContributorProfiles;